
/* Cookie Consent Modal */ 
.consent-banner-modal .ant-modal-body p {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600; 
  text-align: center;
}
.consent-banner-modal .ant-modal-body .--allow-all,
.consent-banner-modal .ant-modal-body .--settings {
  width: 140px;
  min-width: 140px;
  max-width: 140px;
}
.consent-banner-modal .ant-modal-body .--allow-all span {
  font-size: 16px;
  font-weight: 600;
}
.consent-banner-modal .ant-modal-body .--settings span {
  font-size: 14px;
  font-weight: 600;
}
/* /Cookie Consent Modal */ 
/*  Cookie Consent Modal */
.cookie-consent-modal {
  top: var(--offset-not-auth-desktop);
  margin: 0 auto;
}
.cookie-consent-modal .ant-modal-content {
  background: #fff !important;
}
.cookie-consent-modal .ant-modal-close .ant-modal-close-x,
.cookie-consent-modal .ant-modal-close svg {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.cookie-consent-modal .ant-modal-close {
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
}
.cookie-consent-modal .ant-modal-header {
  padding-top: 0;
  padding-bottom: 0;
  background: #fff !important;
}
.cookie-consent-modal .ant-modal-body {
  padding: 20px 0 0 0;
}
.cookie-consent-modal .ant-modal-content {
  border-radius: 10px;
  padding: 20px;
}
.cookie-consent-modal .ant-modal-title {
  color: var(--primary-color) !important;
  font-size: 24px;
  font-weight: 600;
  width: 180px;
  margin: 0 auto;
  line-height: 30px;
}
.cookie-consent-modal .constent-section {
  position: relative;
  top: 0;
  left: 0;
  margin-bottom: 15px;
  color: #444444;
}
.cookie-consent-modal .constent-section .ck-checkbox {
  position: absolute;
  top: -5px;
  right: 0;
  margin-bottom: 0;
  height: 20px;
}
.cookie-consent-modal .constent-section h2,
.cookie-consent-modal .constent-section p {
  letter-spacing: 1px;
  margin-bottom: 5px;
  font-weight: 600;
}
.cookie-consent-modal .constent-section h2 {
  font-size: 16px;
  line-height: 20px;
}
.cookie-consent-modal .constent-section p {
  font-size: 12px;
  line-height: 16px;
}
.cookie-consent-modal .constent-section .allways-active {
  font-weight: 700;
}

.cookie-consent-modal .ant-modal-footer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  gap: 5px;
}

.cookie-consent-modal .ant-modal-footer a,
.cookie-consent-modal .ant-modal-footer a span,
.cookie-consent-modal .ant-modal-footer .save-settings,
.cookie-consent-modal .ant-modal-footer .save-settings span {
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 1330px) {
  .cookie-consent-modal {
    top: var(--offset-not-auth-mobile);
  }
}
/*  Cookie Consent Modal */
