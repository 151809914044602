/* ==========================================================================
  Common styles
  ========================================================================== */
.appointment-modal .field-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.appointment-modal .field-row .field {
  width: 50%;
}
.appointment-modal .field-row .label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  color: var(--primary-color);
}
.appointment-modal .field-row .value {
  display: block;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 20px;
  color: #000;
}
/* ==========================================================================
  End of Common styles
  ========================================================================== */
/* ==========================================================================
  Appointment details header
  ========================================================================== */
.appointment-modal .appointment-details-header {
  margin-bottom: 5px;
}
.appointment-modal .appointment-details-header .ck-navigation-button {
  margin: 0 0 15px -40px;
}
@media (max-width: 992px) {
  .appointment-modal .appointment-details-header .ck-navigation-button {
    margin-left: 0;
  }
}
.appointment-modal .appointment-details-header h2 {
  text-transform: capitalize;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 0px;
}
.appointment-modal .appointment-details-header .service-title {
  text-transform: capitalize;
  font-size: 24px;
  line-height: 30px;
  color: var(--mariner-color);
}
.appointment-modal .appointment-details-header .field-row .value.appointment-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: var(--text-color);
}
.appointment-modal .appointment-details-header .field-row .value.appointment-status div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.appointment-modal .appointment-details-header .field-row .value.invoice-number {
  color: var(--text-color);
  font-weight: 600;
}
.appointment-modal .appointment-details-header .field-row .field {
  width: 25%;
}
@media (max-width: 767px) {
  .appointment-modal .appointment-details-header h2,
  .appointment-modal .appointment-details-header .service-title {
    text-align: center;
  }
  .appointment-modal .appointment-details-header .field-row .field {
    width: 50%;
  }
}
/* ==========================================================================
    End of Appointment details header
    ========================================================================== */
/* ==========================================================================
  Appointment details actions
  ========================================================================== */
.appointment-modal .appointment-details-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 19px;
  margin-bottom: 20px;
}
.appointment-modal .appointment-details-actions > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.appointment-modal .appointment-details-actions .actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0px;
}
.appointment-modal .appointment-details-actions .actions > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.appointment-modal .appointment-details-actions div:not(.formats) .ant-btn:not(.ant-btn-link) {
  width: 260px;
  padding: 0;
}
.appointment-modal .appointment-details-actions div:not(.formats) .ant-btn.ant-btn-link {
  margin-top: 10px;
}
@media (max-width: 767px) {
  .appointment-modal .appointment-details-actions .actions > div {
    gap: 10px;
  }
  .appointment-modal .appointment-details-actions > div {
    flex-direction: column;
  }
}
/* ==========================================================================
  End of Appointment details actions
  ========================================================================== */
/* ==========================================================================
  Appointment details progress
  ========================================================================== */
.appointment-modal .appointment-details-progress {
  margin-bottom: 20px;
}
.appointment-modal .appointment-details-progress h2 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
}
.appointment-modal .appointment-details-progress .ck-divider {
  margin-top: 20px;
}
.appointment-modal .appointment-details-progress .appointment-status-container {
  width: 100%;
  max-width: 900px;
  margin: 25px auto 0 auto;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.appointment-modal .appointment-details-progress .divider-size .ant-divider {
  border-color: #000000;
  margin: 0px !important;
}
.appointment-modal .appointment-details-progress .dots-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
}
.appointment-modal .appointment-details-progress .dots-direction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  z-index: 2;
}
.appointment-modal .appointment-details-progress .dots-direction .dots-container .dot {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background-color: #fff;
  border: 2px solid currentColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.appointment-modal .appointment-details-progress .dots-direction .dots-container .dot .anticon {
  color: #fff;
  display: none;
}
.appointment-modal .appointment-details-progress .dots-direction .dots-container .dot.passed {
  background-color: currentColor;
}
.appointment-modal .appointment-details-progress .dots-direction .dots-container .dot.passed .anticon {
  display: inline-flex;
}
.appointment-modal .appointment-details-progress .dots-direction .dots-container .info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  min-height: 52px !important;
  min-width: 110px !important;
}
.appointment-modal .appointment-details-progress .dots-direction .dots-container .info div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.appointment-modal .appointment-details-progress .dots-direction .dots-container .info p {
  font-size: 14px !important;
  line-height: 17.18px;
  margin: 0px;
}
.appointment-modal .appointment-details-progress .dots-direction .dots-container .info p:not(.date) {
  font-weight: 600;
  max-width: 145px;
}
.appointment-modal .appointment-details-progress .dots-direction .dots-container .info p.clickable {
  color: var(--mariner-color);
  cursor: pointer;
  text-decoration: underline;
}
.appointment-modal .appointment-details-progress .divider-size {
  width: calc(100% - 85px);
  position: relative;
  top: 0px;
  z-index: 1;
}
@media (min-width: 993px) {
  .appointment-modal .appointment-details-progress .appo-progress .divider-size {
    width: 100% !important;
    padding: 0 50px !important;
  }
}
@media (max-width: 992px) {
  .appointment-modal .appointment-details-progress .appo-progress .divider-size {
    width: 1px;
    position: absolute;
    left: 15px;
    top: 15px;
    transform: rotate(90deg);
    width: 275px;
    transform-origin: left;
  }
  .appointment-modal .appointment-details-progress .divider-size .ant-divider {
    width: 100%;
  }
  .appointment-modal .appointment-details-progress .appointment-status-container {
    width: 100%;
    margin: 0;
  }
  .appointment-modal .appo-progress {
    margin-top: 0px;
  }
  .appointment-modal .appointment-details-progress .dots-direction {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 5px;
    height: 305px;
  }
  .appointment-modal .appointment-details-progress .dots-container {
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    height: 34px;
    margin: 0;
  }
  .appointment-modal .appointment-details-progress .dots-direction .dots-container .info {
    margin: 0;
    min-height: unset !important;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .appointment-modal .appointment-details-progress .dots-direction .dots-container .info > div {
    flex-direction: row;
  }
  .appointment-modal .appointment-details-progress .dots-direction .dots-container .dot {
    place-self: self-start;
  }

  /*
  .appointment-modal .appo-progress .divider-size .ant-divider {
    border-color: #000000;
    margin: 0px !important;
    height: 100%;
  }
  */
  /*
  .appointment-modal .appo-progress .dots-direction .dots-container .info {
    align-items: flex-start;
    margin: -25px -210px 55px 0px;
    width: 160px !important;
    max-width: 160px !important;
    min-width: 160px !important;
    min-height: 35px !important;
  }
  */
}


/* ==========================================================================
  End of Appointment details progress
  ========================================================================== */
/* ==========================================================================
  Appointment details table
  ========================================================================== */
.appointment-modal .appointment-details-table {
}

.appointment-modal .appointment-details-table .field-row .value.services,
.appointment-modal .appointment-details-table .field-row .value.reasons {
  white-space: pre-line;
}
@media (max-width: 767px) {
  .appointment-modal .appointment-details-table .field-row {
    flex-direction: column;
  }
  .appointment-modal .appointment-details-table .field-row .field {
    width: 100%;
  }
}
/* ==========================================================================
  End of Appointment details table
  ========================================================================== */



  /* <svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 000 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path></svg> */
