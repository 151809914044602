.config-navbar-header {
  z-index: 1000 !important;
  display: flex;
  flex-direction: column;
  position: fixed !important;
  height: 90px !important;
  padding: 0px 50px !important;
  background-color: white !important;
}
.cf-marging-header {
  padding-top: 71px !important;
}
.cf-extra-marging {
  background-color: white !important;
}
.cf-extra-marging .ant-layout {
  background-color: white !important;
}
.cf-extra-marging .cl-color-control {
  background-color: white !important;
}
.cf-extra-marging .cl-color-control div div div .button_blue {
  margin-top: 30px !important;
}
.cf-extra-marging .cl-margin-control {
  margin-top: 35px !important;
}
.config-navbar-header .for-web {
  display: block !important;
}
.config-navbar-header .for-mob {
  display: none !important;
}
.config-navbar-header .cf-navbar-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
  border-bottom: 1px solid #f05822;
}
.config-navbar-header .cf-navbar-content .cf-title h2 {
  flex: 1;
  font-size: 24px;
  line-height: 29px;
  color: var(--mariner-color);
  margin: 0px !important;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.config-navbar-header .cf-navbar-content .cf-avatar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100% !important;
}
.config-navbar-header .cf-navbar-content .cf-avatar-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border: none !important;
  box-shadow: none !important;
  gap: 20px !important;
  height: 60px !important;
}
.config-navbar-header .cf-navbar-content .cf-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 56px !important;
  height: 56px !important;
  background-color: rgb(86, 150, 226);
}
.config-navbar-header .cf-navbar-content .cf-avatar svg {
  width: 25px !important;
  height: 25px !important;
}
.config-navbar-header .cf-navbar-content .cf-avatar-button p {
  font-size: 16px;
  line-height: 20px;
  font-family: "Emprint-semibold";
  color: var(--primary-color);
  margin: 0px !important;

  max-width: 128px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.config-navbar-header .cf-navbar-content .cf-avatar-button .cf-arrow {
  font-size: 14px !important;
  color: var(--primary-color);
}
.cf-popover-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 10px !important;
  padding: 10px 10px 10px 10px !important;
  border-radius: 10px !important;
}
.cf-popover-content .cf-item-button {
  font-size: 16px !important;
  font-family: "Emprint-semibold";
  text-align: start !important;
  color: var(--primary-color) !important;

  border: none !important;
  box-shadow: none !important;

  padding: 5px 5px !important;
  width: 100%;
}

@media only screen and (max-width: 1060px) {
  .config-navbar-header {
    padding: 0px 2% !important;
  }
  /* .config-navbar-header .for-web {
    display: none !important;
  }
  .config-navbar-header .for-mob {
    display: block !important;
  } */
  .config-navbar-header .cf-navbar-content .cf-logo {
    width: 116px !important;
    height: auto !important;
    background-image: none !important;
    background-size: 0 !important;
  }
  .config-navbar-header .cf-navbar-content .cf-avatar-container {
    height: 70px !important;
    padding: 15px;
  }
  .config-navbar-header .cf-navbar-content .cf-avatar {
    width: 40px !important;
    height: 40px !important;
  }
  .config-navbar-header .cf-navbar-content .cf-avatar svg {
    width: 20px !important;
    height: 20px !important;
  }
}

@media only screen and (max-width: 450px) {
  .cf-marging-header {
    padding-top: 111px !important;
  }
  .config-navbar-header .for-web {
    display: none !important;
  }
  .config-navbar-header .for-mob {
    display: block !important;
  }
  .config-navbar-header .cf-span-left {
    width: 90px;
  }
  .config-navbar-header {
    height: 125px !important;
  }
  .config-navbar-header .cf-navbar-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: none !important;
  }
  .config-navbar-header .cf-title {
    width: 100% !important;
    border-bottom: 1px solid #f05822;
    padding-bottom: 10px !important;
  }
  .config-navbar-header .cf-title h2 {
    font-size: 24px !important;
    line-height: 29px !important;
    color: var(--mariner-color) !important;
    margin: 0px !important;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    width: 100% !important;
  }
}
