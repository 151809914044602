/* MODAL STYLES */
.reschedule-container .warning-message {
  color: var(--secondary-dark-color);
  text-align: center;
  margin: 10px 0;
  font-weight: 600;
}
/* MODAL STYLES */

/* CONTENT */
.reschedule-container {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  width: 100% !important;
}
.reschedule-container .mob {
  display: none;
}
/* END CONTENT */

/* WEEK STYLES */
.reschedule-container .week-container {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}
.reschedule-container .week-container .row-container {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 10px 0px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.reschedule-container .week-container .row-container span svg {
  width: 20px;
  height: 20px;
  color: rgba(71, 85, 105, 1);
}
.reschedule-container .week-container .row-container span svg:hover {
  cursor: pointer;
  color: rgba(71, 85, 105, 0.2);
}
.reschedule-container .week-container .carrousel-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.reschedule-container .week-container .carrousel-container span svg.disabled {
  width: 20px;
  height: 20px;
  color: rgba(47, 102, 242, 0.3);
}
.reschedule-container .week-container .carrousel-container span svg {
  width: 20px;
  height: 20px;
  color: var(--primary-color);
}
.reschedule-container .week-container .carrousel-container span svg:hover {
  cursor: pointer;
  color: rgba(71, 85, 105, 0.2);
}
.reschedule-container .week-container .week-list-size {
  flex: 1;
  overflow-x: scroll;
}
.reschedule-container .week-container .week-list-size::-webkit-scrollbar {
  display: none;
}
.reschedule-container .week-container .week-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  align-self: center;

  width: 100%;
  min-width: 492px !important;

  gap: 10px;
}
.reschedule-container .week-container .date-title {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  font-family: "EMprint-Bold";
  color: rgba(71, 85, 105, 1);
  margin: 0px 10px;
}
.reschedule-container .week-list .disabled {
  background-color: white !important;
  border: 1px solid rgba(170, 170, 170, 1) !important;
}
.reschedule-container .week-list .disabled:hover {
  cursor: not-allowed !important;
}
.reschedule-container .week-list .disabled .str {
  color: rgba(170, 170, 170, 1) !important;
}
.reschedule-container .week-list .disabled .today {
  color: rgba(170, 170, 170, 1) !important;
}
.reschedule-container .week-list .disabled .num {
  color: rgba(170, 170, 170, 1) !important;
}
.reschedule-container .week-list .selected {
  background-color: var(--primary-color) !important;
}
.reschedule-container .week-list .selected .str {
  color: white !important;
}
.reschedule-container .week-list .selected .today {
  color: white !important;
}
.reschedule-container .week-list .selected .num {
  color: white !important;
}
.reschedule-container .week-list .week-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  width: 64px;
  height: 56px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.reschedule-container .week-list .week-card:hover {
  cursor: pointer;
  background-color: rgba(47, 102, 242, 0.1);
}
.reschedule-container .week-list .week-card .str {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--primary-color);
  margin: 0px;
}
.reschedule-container .week-list .week-card .today {
  color: rgba(68, 68, 68, 1) !important;
}
.reschedule-container .week-list .week-card .num {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  font-family: "EMprint-Semibold";
  color: var(--primary-color);
  margin: 0px;
}
/* END WEEK STYLES */

/* DAY SHIFT */
.reschedule-container .day-shift {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  align-self: center;

  border: 1px solid var(--mariner-color);
  border-radius: 8px;

  margin: 20px 0px;

  width: 220px;
  height: 32px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.reschedule-container .day-shift:hover {
  cursor: pointer;
}
.reschedule-container .day-shift.disabled {
  border: 1px solid rgba(170, 170, 170, 1);
}
.reschedule-container .day-shift.disabled:hover {
  cursor: not-allowed;
}
.reschedule-container .day-shift .orange-background {
  background-color: var(--mariner-color) !important;
}
.reschedule-container .day-shift .orange-background p {
  color: white !important;
}
.reschedule-container .day-shift .am.orange-background.disabled {
  background-color: rgba(170, 170, 170, 1) !important;
}
.reschedule-container .day-shift .am.orange-background.disabled p {
  color: white !important;
}
.reschedule-container .day-shift .pm.orange-background.disabled {
  background-color: rgba(170, 170, 170, 1) !important;
}
.reschedule-container .day-shift .pm.orange-background.disabled p {
  color: white !important;
}
.reschedule-container .day-shift .am.disabled {
  background-color: white !important;
}
.reschedule-container .day-shift .am.disabled p {
  color: rgba(170, 170, 170, 1) !important;
}
.reschedule-container .day-shift .pm.disabled {
  background-color: white !important;
}
.reschedule-container .day-shift .pm.disabled p {
  color: rgba(170, 170, 170, 1) !important;
}
.reschedule-container .day-shift .am {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 6.5px;

  width: 110px;
  height: 100%;
}
.reschedule-container .day-shift .am p {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  font-weight: 600;
  color: var(--mariner-color);

  margin: 0px;
}
.reschedule-container .day-shift .pm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 6.5px;

  width: 110px;
  height: 100%;
}
.reschedule-container .day-shift .pm p {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  font-weight: 600;
  color: var(--mariner-color);

  margin: 0px;
}
/* END DAY SHIFT */

/* WEEK STYLES */
.reschedule-container .hour-grid {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;

  justify-content: center;
  align-items: flex-start;
  align-self: center;

  width: 360px;
  max-height: 208px;

  overflow-y: scroll;
}
.reschedule-container .hour-grid::-webkit-scrollbar {
  display: none;
}
.reschedule-container .hour-grid.wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.reschedule-container .hour-grid .hour-card {
  display: inherit;
  border-radius: 8px;
  border: 1px solid var(--primary-color);

  margin: 4px;

  width: 64px;
  height: 40px;
  background-color: white;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.reschedule-container .hour-grid .hour-card:hover {
  cursor: pointer;
  background-color: rgba(47, 102, 242, 0.1);
}
.reschedule-container .hour-grid .hour-card p {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  font-family: "EMprint-Semibold";
  color: rgba(68, 68, 68, 1);

  align-self: center;

  text-align: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;

  margin: 0;
  width: 100%;
}
.reschedule-container .hour-grid .hour-card.selected {
  background-color: var(--primary-color);
}
.reschedule-container .hour-grid .hour-card.selected p {
  color: white;
}
.reschedule-container .hour-grid .hour-card.disabled {
  border: 1px solid rgba(170, 170, 170, 1) !important;
}
.reschedule-container .hour-grid .hour-card.disabled:hover {
  cursor: not-allowed !important;
  background-color: white;
}
.reschedule-container .hour-grid .hour-card.disabled p {
  color: rgba(170, 170, 170, 1) !important;
}
/* END WEEK STYLES */

/* BUTTON CONTAINER */
.reschedule-container .buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
}
.reschedule-container .buttons-container .succes-button {
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0px !important;
  font-family: "EMprint-Semibold" !important;

  border-radius: 10px !important;

  margin-top: 20px;

  width: 240px !important;
  height: 40px !important;
}
.reschedule-container .buttons-container .succes-button span {
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0px !important;
  font-family: "EMprint-Semibold" !important;
}
/* END BUTTON CONTAINER */

@media (max-width: 992px) {

}
@media (max-width: 560px) {
  .reschedule-container .mob {
    display: block;
  }
}
@media (max-width: 420px) {
  .reschedule-container .hour-grid {
    width: 100%;
  }
}
